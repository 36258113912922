import { isNil, omitBy } from 'lodash'

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_APP = '/app'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,

  signIn: (next) =>
    path(
      ROOTS_AUTH,
      next ? `/sign-in?next=${encodeURIComponent(next)}` : '/sign-in'
    ),
  signOut: path(ROOTS_AUTH, '/sign-out'),
  signUp: path(ROOTS_AUTH, '/sign-up'),

  completeSignUp: (email) =>
    path(ROOTS_AUTH, `/complete-sign-up?email=${encodeURIComponent(email)}`),

  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),

  verifyEmail: (afterVerify) =>
    path(
      ROOTS_AUTH,
      `/verify-email?afterVerify=${encodeURIComponent(
        afterVerify ?? PATH_APP.root
      )}`
    ),
  validateEmailVerification: path(ROOTS_AUTH, '/validate-email-verification')
}

export const PATH_PAGE = {
  landing: '/',

  showcase: '/showcase',

  legal: {
    privacyPolicy: '/legal/privacy-policy',
    termsConditions: '/legal/terms-conditions'
  },

  payment: {
    invoice: (invoiceId) => `/payment/invoice/${invoiceId}`,
    multibuyerInvoice: (invoiceId) => `/payment/invoice/mb/${invoiceId}`,
    purchase: (productId) => `/payment/purchase/${productId}`
  },

  error: {
    403: (from) => (from ? `/403?from=${encodeURIComponent(from)}` : '/403'),
    404: (from) => (from ? `/404?from=${encodeURIComponent(from)}` : '/404'),
    500: (from) => (from ? `/500?from=${encodeURIComponent(from)}` : '/500'),
    general: (from) =>
      from ? `/error?from=${encodeURIComponent(from)}` : '/error'
  },

  redirect: {
    promoPage: (productId) => `/redirect/promo-page?product=${productId}`,
    mainPage: (libraryId) => `/redirect/main-page?library=${libraryId}`,
    contentPage: (productId) => `/redirect/content-page?product=${productId}`
  },

  out: (targetURL, action, library) => {
    const params = new URLSearchParams(
      omitBy(
        {
          targetURL: encodeURIComponent(targetURL),
          action: action ?? null,
          library: library ?? null
        },
        isNil
      )
    )

    return `/out?${params.toString()}`
  }
}

export const PATH_APP = {
  root: ROOTS_APP,
  onboarding: {
    setup: path(ROOTS_APP, '/onboarding/setup'),
    createLink: path(ROOTS_APP, '/onboarding/create-link')
  },
  catalog: {
    root: path(ROOTS_APP, '/catalog'),
    update: path(ROOTS_APP, '/catalog/update')
  },
  product: {
    root: path(ROOTS_APP, '/product'),
    list: path(ROOTS_APP, '/product/list'),
    create: (template = 'blank') =>
      path(ROOTS_APP, `/product/create?template=${template}`),
    update: (id) => path(ROOTS_APP, `/product/update/${id}`)
  },
  analytics: {
    root: path(ROOTS_APP, '/analytics'),
    catalog: path(ROOTS_APP, '/analytics/catalog'),
    product: path(ROOTS_APP, '/analytics/product')
  },
  earning: {
    root: path(ROOTS_APP, '/earning'),
    overview: path(ROOTS_APP, '/earning/overview'),
    payoutAccount: path(ROOTS_APP, '/earning/payout-account'),
    withdrawalHistory: path(ROOTS_APP, '/earning/withdrawal-history')
  },
  activity: {
    root: path(ROOTS_APP, '/activity'),
    contentAccess: path(ROOTS_APP, '/activity/content-access'),
    transactionHistory: path(ROOTS_APP, '/activity/transaction-history'),
    multiBuyerTransactionHistory: path(
      ROOTS_APP,
      '/activity/transaction-history/multi-buyer'
    )
  },
  account: {
    root: path(ROOTS_APP, '/account'),
    profile: path(ROOTS_APP, '/account/profile')
  },
  emailBlast: {
    root: path(ROOTS_APP, '/email-blast'),
    create: path(ROOTS_APP, '/email-blast/create'),
    view: (id) => path(ROOTS_APP, `/email-blast/${id}`),
    list: path(ROOTS_APP, '/email-blast/list'),
    topUpReceiptList: path(ROOTS_APP, '/email-blast/top-up-receipt/list'),
    topUpReceipt: (id) => path(ROOTS_APP, `/email-blast/top-up-receipt/${id}`)
  },
  admin: {
    root: path(ROOTS_APP, '/admin'),
    overview: path(ROOTS_APP, '/admin/overview'),
    transaction: path(ROOTS_APP, '/admin/transaction'),
    withdrawal: path(ROOTS_APP, '/admin/withdrawal'),
    creatorRank: path(ROOTS_APP, '/admin/creator-rank'),
    user: path(ROOTS_APP, '/admin/user')
  },
  promo: {
    root: path(ROOTS_APP, '/promo-code'),
    list: path(ROOTS_APP, '/promo-code/list'),
    create: path(ROOTS_APP, '/promo-code/create'),
    update: (id) => path(ROOTS_APP, `/promo-code/update/${id}`)
  }
}
